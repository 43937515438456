// bulma
$main_background: #e7dfc6 !important;
$gap: 32px;
$primary: #2274a5;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,900');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;700');

$family-sans-serif: "Roboto", Helvetica ,Arial, sans-serif;
@import 'plugins/bulma-0.8.2/sass/utilities/_all';
@import 'plugins/bulma-0.8.2/sass/base/_all';
@import 'plugins/bulma-0.8.2/sass/elements/_all';
@import 'plugins/bulma-0.8.2/sass/components/_all';
@import 'plugins/bulma-0.8.2/sass/grid/_all';
@import 'plugins/bulma-0.8.2/sass/layout/_all';
@import 'plugins/bulma-0.8.2/sass/form/_all';
@import 'plugins/bulma-helper-spacing/spacing';

// libs
@import 'plugins/normalize';

// base
@import 'base/z-index';
@import 'base/reset';
@import 'base/mixins';
@import 'base/variables';
@import 'base/structure';
@import 'classes';

// components
@import 'components/quick_edit';

// pages
@import 'pages/home/home_example';


// temp
@import 'temp';



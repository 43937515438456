// transition for links and buttons
button,a {
  transition: color .3s ease,background-color .3s ease,border-color .3s ease,opacity .3s ease;
}

// default cursor
body{
  cursor:default;
}

// remove blue border from focused elements
*:focus {
  outline:0;
}
// mobile container

body { 
	font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
	-ms-hyphens: none;
    -webkit-hyphens: none;
	hyphens: none;

}

html.sr .load-hidden {
    visibility: hidden;
}

#searchfield {

	max-width: 70%;
}

.digitorialheight {
	height:100%;
}

.content {

	h1,h2,h3,h4,h5,h6 {
	color:	#2274a5 !important;
	}

	a {
		transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
		background: transparent;
		display: inline-block;
		font-weight: bold;
		color:	#2274a5 !important;
	}
	a:hover {
		color: #e95e10 !important;
	}

	
}
.dl {

	background-color: #e95e10;
}
.infinite {
	padding: 1rem;
	column-count: 3;

	img {
		width:100%;
		margin-bottom:1rem;
	}

	/* img:nth-child(3n+1) {
		-webkit-box-ordinal-group: 1;
		-webkit-order: 0;
			-ms-flex-order: 0;
				order: 0;
	  }
	  img:nth-child(3n+2) {
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
			-ms-flex-order: 1;
				order: 1;
	  }
	  img:nth-child(3n+3) {
		-webkit-box-ordinal-group: 3;
		-webkit-order: 2;
			-ms-flex-order: 2;
				order: 2;
	  
	  }*/
}




/* These are the classes that are going to be applied: */
.columnsalv { float: left; }
.size-1of3salv { width: 33.333%; }

.footer {

	box-shadow: 0 100vh 0 100vh #fafafa;
}

.icon.is-feature-large {
	width: 10rem;
	height: 10rem;
}

.flickity-prev-next-button.previous {
    left: 0px !important;
}
.flickity-prev-next-button.next {
    right: 0px !important;
}

.newsEntry {
 position: relative;
 width:100%;
 margin: none;
}

.has-image-centered {
	margin-left: auto;
	margin-right: auto;
  }  
 

.selectedcat {
	background: #e95e10;
	color: #fff;

}

.has-text-orange {
	color: #e95e10;

}

.is-button-orange {
	background: #e95e10;
	color: #fff;
   
    cursor: pointer;
    justify-content: center;
    padding-bottom: calc(0.5em - 1px);
    padding-left: 1em;
    padding-right: 1em;
    padding-top: calc(0.5em - 1px);
    text-align: center;
    white-space: nowrap;

}



.page-load-status {
	display: none; /* hidden by default */
	padding-top: 20px;
	border-top: 1px solid #DDD;
	text-align: center;
	color: #DDD;
  }

  .loader-ellips {
	display:none;
	font-size: 20px; /* change size here */
	position: relative;
	width: 4em;
	height: 1em;
	margin: 10px auto;
  }
  
  .loader-ellips__dot {
	display: block;
	width: 1em;
	height: 1em;
	border-radius: 0.5em;
	background: #e95e10; /* change color here */
	position: absolute;
	animation-duration: 0.5s;
	animation-timing-function: ease;
	animation-iteration-count: infinite;
  }
  
  .loader-ellips__dot:nth-child(1),
  .loader-ellips__dot:nth-child(2) {
	left: 0;
  }
  .loader-ellips__dot:nth-child(3) { left: 1.5em; }
  .loader-ellips__dot:nth-child(4) { left: 3em; }
  
  @keyframes reveal {
	from { transform: scale(0.001); }
	to { transform: scale(1); }
  }
  
  @keyframes slide {
	to { transform: translateX(1.5em) }
  }
  
  .loader-ellips__dot:nth-child(1) {
	animation-name: reveal;
  }
  
  .loader-ellips__dot:nth-child(2),
  .loader-ellips__dot:nth-child(3) {
	animation-name: slide;
  }
  
  .loader-ellips__dot:nth-child(4) {
	animation-name: reveal;
	animation-direction: reverse;
  }
  
  

.l-main{
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background-color:$main_background;
}

.l-outside-footer{
	flex: 1 0 auto;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	
}

.l-footer {
	margin-top:4rem;
	margin-bottom: 0rem;
	p{
		padding-left: 1rem;
	}
}

.navbar {
	&.is-transparent {
	background-color: transparent;
	background-image: none;
	}
	}

.viewthreed {

	padding-left: 0rem !important;

}
// external container
.l-external-container{
    max-width: $external_container_width;
    background-color: $external_container_background;
    margin: 0px auto;
}

.cilo {
	
	margin-left: -2rem;
	margin-top: 0rem;
	margin-bottom: 0rem;
	z-index: 31 !important;

   .image {
	max-height: 200px;
	max-width: 200px;
	}
	
}

#panovr {
	min-height:800px !important;
	
}
.frontpage{
	min-height: 500px;
}

.hero {
	
	margin-bottom: 2rem;
}

.news {
	margin-top: 4rem;
	margin-bottom: 4rem;
}



ul {
    list-style:circle;
}


.newsEntry {

    h4 {

        margin-top: 1.5rem;
    }
}

.navbar-burger{
	margin-top: 1rem;

}


.entry_hero_img{
	min-height:500px;
	height:50%;
	background-size: cover;
	background-position: center;
}

.carouselimage{
	min-height:500px;
	height:100%;
	background-size: cover;
	background-position: center;
}

.dot, .dot.is-selected {

	background: #e95e10 !important;
}
.carousel-cell {
	width:90% !important;
	border-radius: 2px;
  }


  .single-carousel img {
	display: block;
	height: 320px;
  }
  


.newsEntry {
	
	img{
	transition: all 0.5s ease;
	}
	
	#overlay{
		position: absolute;
		opacity:0.95;
		display:none;
		width: 100%;
		height: 97%;
	}
	
	}
	
.newsEntry:hover img {
		  
			filter: brightness(130%);
			transform: scale(1.02);
			transition: all 0.5s ease;
			
		
	
		}
.newsEntry:hover #overlay {
		 
	display:block;
	z-index:20;
	
}

.fade-in {
	animation: fadeIn ease 1s;
	-webkit-animation: fadeIn ease 1s;
	-moz-animation: fadeIn ease 1s;
	-o-animation: fadeIn ease 1s;
	-ms-animation: fadeIn ease 1s;
  
  @keyframes fadeIn {
	0% {
	  opacity:0;
	}
	100% {
	  opacity:1;
	}
  }
  
  @-moz-keyframes fadeIn {
	0% {
	  opacity:0;
	}
	100% {
	  opacity:1;
	}
  }
  
  @-webkit-keyframes fadeIn {
	0% {
	  opacity:0;
	}
	100% {
	  opacity:1;
	}
  }
  
  @-o-keyframes fadeIn {
	0% {
	  opacity:0;
	}
	100% {
	  opacity:1;
	}
  }
  
  @-ms-keyframes fadeIn {
	0% {
	  opacity:0;
	}
	100% {
	  opacity:1;
  }
}
}


.button {
	vertical-align: baseline !important;
}

.page-load-status {
	display: block; /* hidden by default */
	padding-top: 20px;
	border-top: 1px solid #DDD;
	text-align: center;
	color: #777;
  }

  .digitorial {

	margin-top:-800px;

  }

  .frontpage {
	  min-height:800px;
  }

@media screen and (max-width: 1023px) {

	.container:not(.touch-full-width){
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		@include touch{
		//	max-width: $mobile_container;
		}
	}

	.infinite {
		padding: 1rem;
		column-count: 2;
	
		img {
			width:100%;
			margin-bottom:1rem;
		}
	}

	.social {
		display: inline !important;
	
		
	}
	
	
	.navbar-menu{
		max-height:calc(100vh - 4rem);
		height: calc(100vh - 64px);
		padding: 0 1rem 1.5rem;
		font-size: 1.6rem;
		text-align: right;
		z-index: auto;
		
		
	}
	.hero {
		margin-top: 2rem;
	}

	.cilo {
	    margin-left: -2rem;
	margin-top: 0rem;
	margin-bottom: 0rem;
	z-index: 31 !important;
		
		.image {
			max-height: 150px;
			max-width: 160px;
			}
	}

	.l-section {

		background-color: #fff !important;
		

	}


	
	
}

@media screen and (max-width: 768px) {


	
  

	nav {
		transition: all 0.5s;
	}
	  
	  
	.scrollUp {
		transition: all 0.5s;
		transform: translateY(-200px) !important;
	}

	.infinite {
		padding: 1rem;
		column-count: 1;
	
		img {
			width:100%;
			margin-bottom:1rem;
		}
	}
	
.cilo {
	margin-left: -2rem;
	margin-top: 0rem;
	margin-bottom: 0rem;
	z-index: 31 !important;
	
	.image {
		max-height: 110px;
		max-width: 100px;
		}
}

	.footlogo {
		
		height:50%;
		width:50%;
	}


	.entry {

		
		width:95%;/*or others percents*/
 
	/*following properties, fix problem*/
	
	transform:translateX(1px);
 
		 
	  .newsEntry {
		  img {
			 
		  }
	  }
	 }

	}
@media screen and (min-width: 769px) {

// fixed footer
nav {
	transition: all 0.5s;
}
  

.carousel-cell {
	
	width:60% !important;
	min-height:348px !important;
	
	
  } 
  
.scrollUpDesktop {
	transition: all 0.5s;
	
	img {
	
		width:40%;
		transition: all 0.5s;
	}
	
}

}


